import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { get } from "../../../utils/service";
import { API_URL } from "../../../api/config";

const SelectProduct = ({
  value,
  onChange,
  mode = null,
  additionalOptions = null,
  except = null,
  system = null,
}) => {
  const [dataSource, setDataSource] = useState([]);

  const getData = async () => {
    let query = "";
    if (system) {
      query += "?system_id=" + system;
    }
    const res = await get(API_URL.select2 + "/produk", query);
    if (res?.status === 200) {
      let response = res?.data;
      if (except !== null) {
        response = res?.data.filter((item) => item.id !== except);
      } else {
      }
      if (additionalOptions?.length > 0) {
        setDataSource([...additionalOptions, ...response]);
      } else {
        setDataSource(response);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [additionalOptions, except, system]);

  return (
    <Select
      allowClear
      showSearch
      mode={mode}
      data-testid="input-system-id"
      placeholder="Pilih Produk"
      optionFilterProp="children"
      options={dataSource || []}
      value={value}
      onChange={onChange}
      fieldNames={{
        label: "text",
        value: "id",
      }}
      filterOption={(input, option) =>
        (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default SelectProduct;
