import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getPaginate } from "../../../utils/service";
import { API_URL } from "../../../api/config";

const SelectSystem = ({
  value,
  onChange,
  mode = null,
  additionalOptions = null,
  except = null,
}) => {
  const [dataSource, setDataSource] = useState([]);

  const getData = async () => {
    const res = await getPaginate(API_URL.system, 1, 1000);
    if (res?.status === 200) {
      let response = res?.data?.data?.data;
      if (except !== null) {
        response = res?.data?.data?.data.filter((item) => item.id !== except);
      } else {
      }
      if (additionalOptions?.length > 0) {
        setDataSource([...additionalOptions, ...response]);
      } else {
        setDataSource(response);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [additionalOptions, except]);

  return (
    <Select
      mode={mode}
      allowClear
      showSearch
      data-testid="input-system-id"
      placeholder="Pilih Sistem"
      optionFilterProp="children"
      options={dataSource || []}
      value={value}
      onChange={onChange}
      fieldNames={{
        label: "name",
        value: "id",
      }}
      filterOption={(input, option) =>
        (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default SelectSystem;
