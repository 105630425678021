import { Col, DatePicker, Form, Radio, Row, Select } from "antd";
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "moment/locale/id";
import moment from "moment";
import SelectSystem from "../../../../layout/components/select/select-system";
import SelectProduct from "../../../../layout/components/select/select-product";

const { Option } = Select;
const FormFilter = forwardRef(({ onFinish, values }, ref) => {
  const history = useHistory();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.submit();
    },
    resetForm: () => {
      form.resetFields();
    },
    hardResetForm: () => {
      form.resetFields();

      const searchParams = new URLSearchParams(window.location.search);
      const paramsToDelete = [];

      searchParams.forEach((value, key) => {
        if (key !== "page" && key !== "search") {
          paramsToDelete.push(key);
        }
      });

      paramsToDelete.forEach((key) => {
        searchParams.delete(key);
      });

      const queryString = searchParams.toString();
      const newUrl = queryString ? `?${queryString}` : "";

      // Navigate to the new URL
      history.push(newUrl, { replace: true });
    },
  }));

  const currentYear = moment().year();
  const startYear = 2020;

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );

  const [selectedSystem, setSelectedSystem] = useState(null);

  useEffect(() => {
    if (values !== null) {
      form.setFieldsValue(values);
    }
  }, [values]);

  return (
    <Form onFinish={onFinish} layout="vertical" form={form} name="form">
      <Row gutter={[16, 16]}>
        <Col span={24} lg={12}>
          <Form.Item name="month" label="Bulan" initialValue={"all"}>
            <Select placeholder="Pilih Bulan">
              <Option key={"all"} value={"all"}>
                Semua Bulan
              </Option>
              {moment.months().map((month, index) => (
                <Option key={index + 1} value={index + 1}>
                  {month}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item name="year" label="Tahun" initialValue={"all"}>
            <Select placeholder="Pilih Tahun">
              <Option key={"all"} value={"all"}>
                Semua Tahun
              </Option>
              {years.map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={24}>
          <Form.Item name="system" label="Sistem">
            <SelectSystem
              mode={"multiple"}
              onChange={(value) => setSelectedSystem(value)}
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={24}>
          <Form.Item name="product" label="Produk">
            <SelectProduct mode={"multiple"} system={selectedSystem} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export default FormFilter;
